import { Button, HStack, Text, useToast } from '@chakra-ui/react'
import { useGlobals } from 'hooks/helpers/useGlobals'
import React, { useEffect, useState } from 'react'
import CardPage from 'v2/components/pages/CardPage'
import { useCustomersByAuth } from 'v2/hooks/api/customer/useCustomersByAuth'
import { apiClient } from 'v2/services/clients/apiClient'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'
import { createCustomerSearchPicker } from 'v2/services/app/factories/forms/createCustomerSearchPicker'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  ExcelStatusEnum,
  ExcelTypeEnum,
  OperationExcelModel
} from 'interfaces/operation_excel'
import { onlyBase64File, validateFild } from 'helpers/validFilds'
import { clearLoading, setGlobalMessage, showLoading } from 'redux/actions'
import { FileProp } from 'v2/components/forms/documents/contracts'
import moment from 'moment'
import { Col } from 'v2/components/shared/Col'
import { useDispatch } from 'react-redux'
import { isUnauthorized } from 'helpers/errors'

interface OperationFormFiles {
  attachment?: FileProp[]
  dascamExcel?: FileProp[]
  clientExcel?: FileProp[]
}

export interface OperationForm extends OperationFormFiles {
  codClient?: string
  clientName?: string
  cnpjCpf?: string
  clientObservation?: string
  dascamObservation?: string
  status?: ExcelStatusEnum
}

const SpreadsheetsOperationPage: React.FC = () => {
  const { user, isDascam } = useGlobals()
  const params = useParams<any>()
  const [spreadsheet, setSpreadsheet] = useState<OperationForm>({})
  const [model, setModel] = useState<any>({})
  const [disabled, setDisabled] = useState<boolean>(false)

  const { data: customers } = useCustomersByAuth()
  const history = useHistory()
  const dispatch = useDispatch()
  const toast = useToast()
  const toOperationExcel = (
    data: OperationForm,
    additionals?: Partial<OperationExcelModel>
  ) => {
    const clientExcel = onlyBase64File(
      data.clientExcel?.length ? data.clientExcel![0].src : undefined
    )
    const dascamExcel = onlyBase64File(
      data.dascamExcel?.length ? data.dascamExcel![0].src : undefined
    )
    const attachmentFile = onlyBase64File(
      data.attachment?.length ? data.attachment![0].src : undefined
    )

    const operationExcel: Partial<OperationExcelModel> = {
      id: model?.id,
      user_id: model?.user_id || user?.id!,
      attachment: attachmentFile,
      attachment_type: attachmentFile
        ? data.attachment![0].mimeType
        : undefined,
      attachment_name: attachmentFile
        ? `attachment-${model?.id}-${moment().format('YYYYMMDDHHmmss')}`
        : undefined,
      product_type: ExcelTypeEnum.IMPORTACAO,
      client_excel: clientExcel,
      client_excel_type: clientExcel
        ? data.clientExcel![0].mimeType
        : undefined,
      client_excel_name: clientExcel
        ? `clientexcel-${model?.id}-${moment().format('YYYYMMDDHHmmss')}`
        : undefined,
      client_doc: data.cnpjCpf,
      client_name: data.clientName,
      client_observation: data.clientObservation,
      created_by_id: model?.created_by_id || user?.id,
      dascam_excel: dascamExcel,
      dascam_excel_type: dascamExcel?.length
        ? data.dascamExcel![0].mimeType
        : undefined,
      dascam_excel_name: dascamExcel?.length
        ? `dascamexcel-${model?.id}-${moment().format('YYYYMMDDHHmmss')}`
        : undefined,
      created_date: model?.created_date || new Date(),
      dascam_observation: data.dascamObservation,
      status: ExcelStatusEnum.SENT,
      updated_by_id: user?.id,
      updated_date: new Date(),
      user_syscambio: Number(data.codClient),
      notification_id: !isDascam ? Number(user.id_syscambio) :  undefined
    }

    const nextOperation = {
      ...operationExcel,
      ...additionals
    }

    return nextOperation
  }

  const onSubmit = async (status = ExcelStatusEnum.PENDING) => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          subMessage: 'Salvando, aguarde!',
          isLoading: true
        })
      )
      if (status === ExcelStatusEnum.PENDING) {
        if (
          !(
            validateFild(spreadsheet, ['codClient']) &&
            spreadsheet.attachment !== undefined &&
            spreadsheet.attachment?.length > 0
          )
        ) {
          return toast({
            title: 'Campos Obrigatórios!!',
            position: 'bottom',
            description:
              'Por favor, verifique os campos obrigatórios e tente novamente.',
            status: 'warning',
            duration: 3000,
            isClosable: true
          })
        }
      } else {
        if (
          !(
            validateFild(spreadsheet, ['codClient']) &&
            spreadsheet.attachment !== undefined &&
            spreadsheet.attachment?.length > 0
          )
        ) {
          return toast({
            title: 'Campos Obrigatórios!!',
            position: 'bottom',
            description:
              'Por favor, verifique os campos obrigatórios e tente novamente.',
            status: 'warning',
            duration: 3000,
            isClosable: true
          })
        }
      }
      const data = toOperationExcel(spreadsheet)
      data.status = status
      await apiClient.operations
        .processSaveSpreadsheet(data)
        .then((res) => {
          console.log('results', res.data)
          history.push(`/operations?mode=two`)
        })
        .catch((error) => {
          if (isUnauthorized(error)) {
            history.push('/unauthorized')
          } else {
            dispatch(
              setGlobalMessage({
                message: `Não foi possível salvar.`,
                type: 'ERROR'
              })
            )
          }
        })
    } catch (error) {
    } finally {
      dispatch(clearLoading())
    }
  }

  useEffect(() => {
    const path = window.location.pathname
    setDisabled(path.indexOf('/spreadsheets/show') >= 0)
    if (params?.id) {
      dispatch(
        showLoading({
          message: 'Carregando',
          subMessage: 'Buscando perfil, aguarde!',
          isLoading: true
        })
      )
      apiClient.operations
        .processLoadSpreadSheets({ id: params.id as number }, isDascam)
        .then((response) => {
          const excel = response.data.excel[0]
          setModel(excel)
          setSpreadsheet(() => ({
            attachment: excel?.attachment
              ? [
                  {
                    isBase64: false,
                    src: excel?.attachment
                  }
                ]
              : [],
            clientExcel: excel?.client_excel
              ? [
                  {
                    isBase64: false,
                    src: excel?.client_excel
                  }
                ]
              : [],
            dascamExcel: excel?.dascam_excel
              ? [
                  {
                    isBase64: false,
                    src: excel?.dascam_excel
                  }
                ]
              : [],
            status: excel?.status,
            clientName: excel?.client_name,
            cnpjCpf: excel?.client_doc,
            codClient: `${excel?.user_syscambio}`,
            clientObservation: excel?.client_observation,
            dascamObservation: excel?.dascam_observation,
            customer: {
              id_syscambio: excel.user_syscambio,
              document: excel.client_doc,
              corporate_name: excel.client_name
            }
          }))
        })
        .catch((e) => {
          console.log(e)
        })
      dispatch(clearLoading())
    }
  }, [params])

  useEffect(() => {
    console.log(user.permission_group_company)
  }, [])

  //if (!customers || !currencies) return <></>

  return (
    <CardPage
      title={params?.id ? 'Editar Envio de Planilha' : 'Enviar Nova Planilha'}
      _headerBottom={<Text my={2} fontSize={'xs'} />}
      _headerRight={
        <HStack my={2} fontSize={'xs'}>
          <Link to={`/operations?mode=two`}>
            <Button
              variant={'ghost'}
              color={'terciary.400'}
              colorScheme={'terciary_hover'}
            >
              Voltar
            </Button>
          </Link>
          {!disabled && !isDascam && (
            <Button
              variant={'outline'}
              colorScheme={'green'}
              onClick={() => {
                onSubmit(ExcelStatusEnum.PENDING)
              }}
            >
              Salvar Rascunho
            </Button>
          )}
          {!disabled && !isDascam && (
            <Button
              colorScheme={'primary'}
              onClick={() => {
                onSubmit(ExcelStatusEnum.SENT)
              }}
            >
              Enviar Planilha
            </Button>
          )}
          {spreadsheet.status === ExcelStatusEnum.SENT && isDascam && (
            <Button
              colorScheme={'green'}
              onClick={() => {
                onSubmit(ExcelStatusEnum.CORRECTION)
              }}
            >
              Devolver para Correção
            </Button>
          )}
          {spreadsheet.status === ExcelStatusEnum.SENT && isDascam && (
            <Button
              colorScheme={'primary'}
              onClick={() => {
                onSubmit(ExcelStatusEnum.APPROVAL)
              }}
            >
              Planilha Correta
            </Button>
          )}
        </HStack>
      }
    >
      <Col px={2} w={'100%'} mt={2}>
        <SimpleFormGenerator
          rows={[
            {
              columns: 1,
              fields: [
                {
                  name: '1st',
                  type: 'heading',
                  label: 'DADOS PARA OPERAÇÃO',
                  as: 'h4',
                  size: 'md'
                }
              ]
            },
            {
              columns: 3,
              fields: [
                createCustomerSearchPicker(
                  user.company_id ?? user.id!,
                  user.cad_type === 'AUTORIZADO'
                    ? user.permission_group_company ?? []
                    : undefined,
                  {
                    label: 'Cod. Cliente *',
                    name: 'customer',
                    labelKey: 'id_syscambio',
                    readOnly: disabled || isDascam
                  }
                ),
                {
                  type: 'text',
                  name: 'customer.corporate_name',
                  label: 'Nome',
                  readOnly: true
                },
                {
                  type: 'text',
                  name: 'customer.document',
                  label: 'CNPJ/CPF',
                  readOnly: true,
                  mask: (value) => {
                    if (value.length > 11) {
                      return value
                        .replace(/\D/g, '')
                        .replace(/(\d{2})(\d)/, '$1.$2')
                        .replace(/(\d{3})(\d)/, '$1.$2')
                        .replace(/(\d{3})(\d)/, '$1/$2')
                        .replace(/(\d{4})(\d{1,2})/, '$1-$2')
                        .replace(/(-\d{2})\d+?$/, '$1')
                    } else {
                      return value
                        .replace(/\D/g, '')
                        .replace(/(\d{2})(\d)/, '$1/$2')
                        .replace(/(\d{7})(\d)/, '$1-$2')
                        .replace(/(-\d{1})\d+?$/, '$1')
                    }
                  }
                }
              ]
            },
            {
              columns: 1,
              fields: [
                {
                  name: '2nd',
                  type: 'heading',
                  label: 'PLANILHAS/DOCUMENTOS',
                  as: 'h4',
                  size: 'md'
                }
              ]
            },
            {
              columns: 1,
              fields: [
                {
                  name: '2nd',
                  type: 'heading',
                  label:
                    'Em caso de multiplos arquivos, use um arquivo do tipo .zip',
                  as: 'h1',
                  size: 'xs'
                }
              ]
            },
            {
              columns: 3,
              fields: [
                //{
                //  name: 'clientExcel',
                //  label: 'Planilha para importação *',
                //  type: 'files',
                //  extensions: ['csv', 'xls', 'xlsx'],
                //  readOnly: disabled || isDascam,
                //  viewMode: true
                //},
                {
                  name: 'attachment',
                  label: 'Anexo de Documentos* (planilha, pdf, zip)',
                  type: 'files',
                  extensions: ['csv', 'xls', 'xlsx', 'zip', 'pdf'],
                  readOnly: disabled || isDascam,
                  viewMode: true
                }
              ]
            },
            {
              columns: 1,
              fields: [
                {
                  name: 'clientObservation',
                  label: 'Observações',
                  type: 'textarea',
                  readOnly: disabled || isDascam
                }
              ]
            },
            {
              columns: 1,
              fields: [
                {
                  name: '3rd',
                  type: 'heading',
                  label: 'OBSERVAÇÕES DASCAM',
                  as: 'h4',
                  size: 'md'
                },
                {
                  name: 'dascamExcel',
                  label: 'Anexo',
                  type: 'files',
                  extensions: ['csv', 'xls', 'xlsx', 'zip', 'pdf'],
                  readOnly: !isDascam,
                  viewMode: true
                },
                {
                  name: 'dascamObservation',
                  label: 'Observações',
                  type: 'textarea',
                  readOnly: !isDascam
                }
              ]
            }
          ]}
          value={spreadsheet}
          onChange={(value) => {
            if (!value) return
            if (value.document) {
              if (value.document!.length > 11) {
                value.document = value.document
                  .replace(/\D/g, '')
                  .replace(/(\d{2})(\d)/, '$1.$2')
                  .replace(/(\d{3})(\d)/, '$1.$2')
                  .replace(/(\d{3})(\d)/, '$1/$2')
                  .replace(/(\d{4})(\d{1,2})/, '$1-$2')
                  .replace(/(-\d{2})\d+?$/, '$1')
              } else {
                value.document = value.document
                  .replace(/\D/g, '')
                  .replace(/(\d{2})(\d)/, '$1/$2')
                  .replace(/(\d{7})(\d)/, '$1-$2')
                  .replace(/(-\d{1})\d+?$/, '$1')
              }
            }
            const customer = { ...value.customer }
            setSpreadsheet((current) => {
              if (customer) {
                current.codClient = customer.id_syscambio
                current.cnpjCpf = customer.document
                current.clientName = customer.corporate_name
              }
              if (value.clientExcel) {
                current.clientExcel = value.clientExcel
              }
              if (value.attachment) {
                current.attachment = value.attachment
              }
              if (value.dascamExcel) {
                current.dascamExcel = value.dascamExcel
              }
              if (value.clientObservation !== null) {
                current.clientObservation = value.clientObservation
              }
              if (value.dascamObservation !== null) {
                current.dascamObservation = value.dascamObservation
              }
              return {
                ...value,
                ...current
              }
            })
          }}
        />
      </Col>
    </CardPage>
  )
}

export default SpreadsheetsOperationPage
