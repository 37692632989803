import {
  Button,
  HStack,
  Progress,
  SimpleGrid,
  Text,
  useBoolean,
  useToast
} from '@chakra-ui/react'
import { useGlobals } from 'hooks/helpers/useGlobals'
import React, { useMemo, useState } from 'react'
import { DocumentItemProps } from 'v2/components/forms/documents/contracts'
import OperationDocuments from 'v2/components/modules/operation/OperationDocuments'
import OperationInfos, {
  OperationInfosValue
} from 'v2/components/modules/operation/OperationInfos'
import CardPage from 'v2/components/pages/CardPage'
import { useCurrencyQuery } from 'v2/hooks/api/currency/useCurrencyQuery'
import { useCustomersByAuth } from 'v2/hooks/api/customer/useCustomersByAuth'
import { apiClient } from 'v2/services/clients/apiClient'
import { Link, useHistory } from 'react-router-dom'
import { createOperationRequestData } from 'v2/services/app/factories/request/createOperationRequestData'
import { Forms } from 'v2/components/modules/operation/DocumentSetModal'

const CreateOperationPage: React.FC = () => {
  const toast = useToast()
  const { user } = useGlobals()
  const history = useHistory()
  const { data: customers } = useCustomersByAuth()
  const { data: currencies } = useCurrencyQuery()
  const [isLoadingSave, loadSave] = useBoolean()
  const [operationInfos, setOperationInfos] = useState<OperationInfosValue>(
    {} as OperationInfosValue
  )

  const [documents, setDocuments] = useState<DocumentItemProps[]>([])

  const onSubmit = async (status = 'PENDING') => {
    try {
      loadSave.on()

      if (status === 'SENT') {
        const valueProcess = operationInfos.process.value
        let valueInvoice = 0
        if (
          !operationInfos.payerReceiver?.CodPagReg ||
          !operationInfos.currency ||
          !operationInfos.customer ||
          !operationInfos.process.expiration ||
          !operationInfos.process.type ||
          !operationInfos.process.value
        ) {
          toast({
            title: 'Campos Obrigatórios',
            description: 'É necessario preencher todos os campos com *!',
            status: 'error',
            duration: 3000,
            isClosable: true
          })
          return null
        }

        if (!documents || documents.length === 0) {
          toast({
            title: 'Documentos',
            description: 'É necessario ter documentos no processo!',
            status: 'error',
            duration: 3000,
            isClosable: true
          })
          return null
        }

        documents.forEach((e) => {
          if (e.document_type === 'FATURA') {
            valueInvoice = Number((valueInvoice + e.value_applied).toFixed(2))
          }
        })

        if (valueProcess !== valueInvoice) {
          toast({
            title: 'Documentos',
            description:
              'É necessario que o valor de faturas bata com o valor do processo!',
            status: 'error',
            duration: 3000,
            isClosable: true
          })
          return null
        }
      }

      await apiClient.operations
        .send(
          createOperationRequestData({
            operationInfos,
            documents,
            status,
            userId: user.id,
            id_syscambio: Number(user.id_syscambio)
          })
        )
        .then((value) => {
          if (value.id) {
            history.push(`/operations/edit/${value.id}`)
          }
        })
    } catch (error) {
    } finally {
      loadSave.off()
    }
  }

  const formsEnabled = useMemo<Forms[]>(() => {
    if (operationInfos.process?.type === 'IMPORTACAO') {
      //return ['duimp', 'di', 'dsi', 'li', 'fatura', 'conhecimento']
      return ['di', 'fatura', 'conhecimento', 'duimp']
    }

    if (operationInfos.process?.type === 'REMESSA') {
      return ['fatura', 'due']
    }

    if (operationInfos.process?.type === 'INGRESSO') {
      return ['fatura', 'op']
    }

    if (operationInfos.process?.type === 'EXPORTACAO') {
      return ['due', 'fatura', 'op']
    }

    return []
  }, [operationInfos.process])

  if (!customers || !currencies)
    return (
      <CardPage
        title={'Carregando...'}
        _headerRight={
          <HStack>
            <Link to={`/operations`}>
              <Button
                variant={'ghost'}
                color={'terciary.400'}
                colorScheme={'terciary_hover'}
              >
                Voltar
              </Button>
            </Link>
          </HStack>
        }
      >
        <Progress isIndeterminate colorScheme={'secondary'} h={1} />
      </CardPage>
    )

  return (
    <CardPage
      title={'Novo Processo'}
      _headerBottom={<Text my={2} fontSize={'xs'} />}
      _headerRight={
        <HStack>
          <Link to={`/operations`}>
            <Button
              variant={'ghost'}
              color={'terciary.400'}
              colorScheme={'terciary_hover'}
            >
              Voltar
            </Button>
          </Link>
          <Button
            variant={'outline'}
            colorScheme={'green'}
            onClick={() => {
              onSubmit('PENDING')
            }}
            isLoading={isLoadingSave}
          >
            Salvar como Rascunho
          </Button>
          <Button
            colorScheme={'primary'}
            onClick={() => {
              onSubmit('SENT')
            }}
            isLoading={isLoadingSave}
          >
            Enviar para Análise
          </Button>
        </HStack>
      }
    >
      <SimpleGrid p={4} gap={4}>
        <OperationInfos
          currencies={currencies}
          customers={customers}
          value={operationInfos}
          onChange={setOperationInfos}
        />
        {formsEnabled.length > 0 && operationInfos.customer?.id_syscambio && (
          <OperationDocuments
            documents={documents}
            onChange={setDocuments}
            formsEnabled={formsEnabled}
            idSyscambio={`${operationInfos.customer.id_syscambio}`}
            operation={operationInfos}
          />
        )}
      </SimpleGrid>
    </CardPage>
  )
}

export default CreateOperationPage
