import { DocumentItemProps } from "v2/components/forms/documents/contracts";
import { OperationInfosValue } from "v2/components/modules/operation/OperationInfos";
import { ApiOperations } from "v2/services/api/lib/api.operations";
import * as uuid from "uuid";
import moment from "moment";

interface CreateOperationRequestDataProps {
    id?: number;
    userId?: number;
    operationInfos: OperationInfosValue;
    documents: DocumentItemProps[];
    status: string;
    created_by_id?: number;
    created_date?: string;
    updated_by_id?: number;
    updated_date?: string;
    observation?: ApiOperations.ObservationProps;
    id_syscambio?: number;
}

export function createOperationRequestData({
    id,
    userId,
    operationInfos,
    documents,
    status,
    created_by_id,
    created_date,
    updated_by_id,
    updated_date,
    observation,
    id_syscambio
}: CreateOperationRequestDataProps): Partial<ApiOperations.OperationProps> {
    return {
        id,
        user_id: userId,
        product_type: operationInfos.process.type,
        process_number: Number(unmask(operationInfos.process.number)),
        id_syscambio: id_syscambio,//operationInfos.customer.id_syscambio,
        client_name: operationInfos.customer.fantasy_name,
        client_document: unmask(operationInfos.customer.document!),
        beneficiary_code: operationInfos?.payerReceiver?.CodPagReg as any,
        beneficiary_name: operationInfos?.payerReceiver?.NomeBeneficiario,
        currency_code: operationInfos.currency.Codigo,
        currency_iso: operationInfos.currency.CodigoISO,
        currency_name: operationInfos.currency.Nome,
        process_value: Number(operationInfos.process.value).toFixed(2),
        refCli: operationInfos.process.refCli,
        process_expiration: moment(operationInfos.process.expiration).format('yyyy-MM-DD'),
        status,
        created_by_id: created_by_id,
        created_date: created_date,
        updated_by_id: updated_by_id,
        updated_date: updated_date,
        documents: documents.map<any>((doc) => ({
            attached_file: doc.files?.[0]?.src,
            document_type: doc.document_type,
            balance_avaliable: doc.balance,
            applied_value: doc.value_applied,
            total_value: doc.value_total,
            document_number: unmask(doc.document_number),
            document_reference: doc.reference,
            document_key: doc.document_key,
            protocol: doc.protocol,
            clearence_document: doc.clearence_document,
            attached_name: doc.files?.[0]?.name
                ? doc.files?.[0]?.name
                : `Arquivo_Sem_Nome`,
            attached: doc.files?.[0]?.mimeType,
            set_number: doc.group_number,
        })),
        beneficiary_info: {
            beneficiary: operationInfos.beneficiatyBank as any,
            intermediary: operationInfos.intermediaryBank as any,
        },
        observation
    };
}


function unmask(value: string): string | undefined {
    if (!value) {
        return undefined;
    }
    return value.replace(/[^\w\s]/gi, '');
}
