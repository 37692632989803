import {
  Box,
  Button,
  Collapse,
  HStack,
  IconButton,
  Progress,
  Select,
  useBoolean,
  VStack
} from '@chakra-ui/react'
import Historic from 'components/generic/Historic'
import { isUnauthorized } from 'helpers/errors'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { OperationModel, OperationStatusEnum } from 'interfaces/operation'
import {
  ExcelStatusEnum,
  OperationExcelModel
} from 'interfaces/operation_excel'
import { UserTypeEnum } from 'interfaces/user'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import { BiHistory, BiShow } from 'react-icons/bi'

import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  clearGlobalMessage,
  clearLoading,
  setGlobalMessage,
  showLoading
} from 'redux/actions'
import CardPage from 'v2/components/pages/CardPage'
import { Col } from 'v2/components/shared/Col'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'

import { useCurrencyQuery } from 'v2/hooks/api/currency/useCurrencyQuery'
import { useHashData } from 'v2/hooks/helpers/useHashData'
import { createCurrencySearchPicker } from 'v2/services/app/factories/forms/createCurrencySearchPicker'
import { createCustomerSearchPicker } from 'v2/services/app/factories/forms/createCustomerSearchPicker'
import { createPayerReceiverSearchPicker } from 'v2/services/app/factories/forms/createPayerReceiverSearchPicker'
import { apiClient } from 'v2/services/clients/apiClient'
import DataTable, { TableColumn } from 'react-data-table-component'
import { formatCNPJ, formatCPF, formatProduct } from 'helpers/formats'
import { getMoeda } from 'helpers/moedas'
import AppContext from 'hooks/helpers/useLastFilter'
import { Permissions } from 'interfaces/web/permission'
import styled from 'styled-components'

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`

const FilterComponent = ({ filterText, onFilter }: any) => (
  <>
    <TextField
      id='search'
      type='text'
      placeholder='Buscar'
      aria-label='Search Input'
      value={filterText}
      onChange={onFilter}
    />
  </>
)

const ListOperationsPage: React.FC = () => {
  const { user, isDascam, isAutorizado, hasPermissions } = useGlobals()

  const permCreation = isAutorizado
    ? !hasPermissions([Permissions.PROCESS_CREATE])
    : false
  const permDelete = isAutorizado
    ? hasPermissions([Permissions.PROCESS_CREATE])
    : true
  const permEdit = isAutorizado
    ? hasPermissions([Permissions.PROCESS_EDIT, Permissions.PROCESS_VIEW])
    : true
  const permView = isAutorizado
    ? hasPermissions([Permissions.PROCESS_VIEW])
    : true

  const date = new Date().toISOString().split('T')[0]
  const Default = AppContext?.operations
    ? AppContext?.operations
    : user.cad_type === UserTypeEnum.DASCAM
    ? {
        start_date: date,
        end_date: date,
        status: 'SENT',
        mode: 'two'
      }
    : {
        start_date: date,
        end_date: date,
        mode: 'two'
      }

  const [filterTextExternal, setFilterTextExternal] = React.useState<string>('')
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <FilterComponent
        filterText={filterTextExternal}
        onFilter={(e: any) => {
          setFilterTextExternal(e.target.value)
        }}
      />
    )
  }, [filterTextExternal])

  useEffect(() => {
    console.log(AppContext?.operations)
    redirect(hashData)
    if (hashData.mode === 'one') {
      setMode('one')
      loadProcess()
    } else {
      setMode('two')
      loadExcel()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const dispatch = useDispatch()
  const history = useHistory()
  const { hashData, redirect } = useHashData(Default)
  const [isLoading, loading] = useBoolean()
  const { data: currencies } = useCurrencyQuery()
  const [operations, setOperations] = useState<OperationModel[]>([])
  const [spreadsheets, setSpreadsheets] = useState<OperationExcelModel[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(true)
  const [operation, setOperation] = useState<OperationModel>(
    {} as OperationModel
  )
  const [groupList, setGroupList] = useState<any[]>([])
  const [mode, setMode] = useState<'one' | 'two'>('one')

  const filterAPI = useMemo(() => {
    if (!hashData) return undefined

    const { start_date, end_date, status } = hashData

    const isDascam = user.cad_type === UserTypeEnum.DASCAM
    let id_syscambio /*= isDascam
      ? hashData.customer?.id_syscambio
      : hashData.customer?.id_syscambio || user.id_syscambio*/

    if (isDascam) {
      id_syscambio = hashData.customer?.id_syscambio
    } else if (user.cad_type === 'AUTORIZADO') {
      if (user.permission_group_company) {
        if (user.permission_group_company.includes(Number(user.id_syscambio))) {
          //id_syscambio = user.id_syscambio
        } else {
          if (hashData.customer?.id_syscambio) {
            id_syscambio = hashData.customer?.id_syscambio ?? null
          } else {
            return undefined
          }
        }
      } else {
        if (hashData.customer?.id_syscambio) {
          id_syscambio = hashData.customer?.id_syscambio ?? null
        } else {
          return undefined
        }
      }
    } else {
      id_syscambio = hashData.customer?.id_syscambio //|| user.id_syscambio
    }
    return {
      //user_id: isDascam ? undefined : user.id,
      client_name: hashData.customer?.fantasy_name,
      id_syscambio: id_syscambio,
      date: start_date && end_date ? [start_date, end_date] : undefined,
      status: status ? [status] : undefined,
      product_type: hashData.operation_type,
      currency_name: hashData.currency_code?.Nome,
      limit: 2000,
      offset: 0
    }
  }, [hashData, user])

  const columnsOperationExcel: TableColumn<OperationExcelModel>[] = [
    {
      name: 'id',
      selector: (row) => row.id,
      sortable: true,
      width: '90px'
    },
    {
      name: 'Empresa',
      selector: (row) => row.client_name ?? '---',
      sortable: true
    },
    {
      name: 'Data de Lançamento',
      selector: (row) =>
        row.created_date
          ? new Date(row.created_date)
              ?.toLocaleString('pt-BR', { timeZone: 'UTC' })
              .split(',')[0]
          : '---',
      sortable: true,
      width: '180px'
    },
    {
      name: 'Status',
      selector: (row) =>
        row.status ? ExcelStatusEnum.format(row.status) : '---',
      width: '160px'
    },
    {
      name: 'AÇÕES',
      cell: (row) => action(row),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }
  ]
  const columns: TableColumn<OperationModel>[] = [
    {
      name: 'Ações',
      cell: (row) => (
        <HStack m={1}>
          {permEdit &&
            (row.status === OperationStatusEnum.PENDING ||
              row.status === OperationStatusEnum.CORRECTION) && (
              <Link to={`/operations/edit/${row.id}`} title='Editar'>
                <IconButton
                  aria-label='Editar'
                  size={'sm'}
                  colorScheme={'secondary'}
                  color={'primary.400'}
                >
                  <AiOutlineEdit size={16} />
                </IconButton>
              </Link>
            )}
          {permView && (
            <Link to={`/operations/show/${row.id}`} title='Visualizar'>
              <IconButton
                aria-label='Visualizar'
                size={'sm'}
                colorScheme={'blue'}
              >
                {!isDascam ? <BiShow size={16} /> : <AiOutlineEdit size={16} />}
              </IconButton>
            </Link>
          )}
          {permDelete &&
            (row.status === OperationStatusEnum.PENDING ||
              row.status === OperationStatusEnum.CORRECTION) && (
              <IconButton
                aria-label='Deletar'
                title='Excluir'
                size={'sm'}
                colorScheme={'red'}
                onClick={() => {
                  if (
                    row.status === OperationStatusEnum.PENDING ||
                    row.status === OperationStatusEnum.CORRECTION
                  ) {
                    handleDeleteItem(row.id ?? 0)
                  } else {
                    handleDeleteOperation(row.id ?? 0)
                  }
                }}
              >
                <AiOutlineDelete size={16} />
              </IconButton>
            )}
          <IconButton
            aria-label='Histórico'
            title='Histórico'
            size={'sm'}
            onClick={() => {
              setShowModal(!showModal)
              setOperation(row)
            }}
            colorScheme={'primary'}
          >
            <BiHistory size={16} />
          </IconButton>
        </HStack>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '160px'
    },
    {
      name: 'id',
      selector: (row) => row.id ?? '',
      sortable: true,
      width: '65px'
    },
    {
      name: 'Status',
      selector: (row) =>
        row.status ? OperationStatusEnum.format(row.status) : '---',
      width: '100px'
    },
    {
      name: 'Tipo de Contrato',
      selector: (row) => formatProduct(row.product_type ?? ''),
      sortable: true,
      width: '160px'
    },
    {
      name: 'Cliente',
      selector: (row) => row.client_name ?? '---',
      sortable: true,
      width: '260px'
    },
    {
      name: 'Data de Lançamento',
      selector: (row) =>
        row.created_date
          ? new Date(row.created_date)
              .toLocaleString('pt-BR', {
                timeZone: 'UTC'
              })
              .replace(',', '')
          : '',
      sortable: true,
      width: '170px'
    },
    {
      name: 'Pagador/Recebedor',
      selector: (row) => row.beneficiary_name ?? '',
      width: '300px'
    },
    {
      name: 'Moeda',
      selector: (row) => getMoeda(Number(row.currency_code ?? 0)) ?? '',
      minWidth: '80px'
    },
    {
      name: 'Valor',
      selector: (row) =>
        Number(row.process_value)?.toLocaleString('pt-br', {
          minimumFractionDigits: 2
        }),
      width: '150px',
      right: true
    }
  ]
  function action (data: any) {
    return (
      <HStack m={1}>
        <Link to={`/spreadsheets/show/${data.id}`} title='Visualizar'>
          <IconButton
            disabled={!permView}
            aria-label='Visualizar'
            size={'sm'}
            colorScheme={'blue'}
          >
            {!isDascam ? <BiShow size={16} /> : <AiOutlineEdit size={16} />}
          </IconButton>
        </Link>
        {!isDascam &&
          permEdit &&
          (data.status === OperationStatusEnum.PENDING ||
            data.status === OperationStatusEnum.CORRECTION) && (
            <Link to={`/spreadsheets/edit/${data.id}`} title='Editar'>
              <IconButton
                aria-label='Editar'
                size={'sm'}
                colorScheme={'secondary'}
                color={'primary.400'}
              >
                <AiOutlineEdit size={16} />
              </IconButton>
            </Link>
          )}
      </HStack>
    )
  }
  function clearData () {
    redirect({})
    setReload(true)
  }
  const handleDeleteItem = (id: number) => {
    dispatch(
      setGlobalMessage({
        message:
          'Deseja realmente excluir este item? \n O item será excluido direto da base de dados',
        type: 'MESSAGE',
        buttons: (
          <>
            <Button
              variant={'outline'}
              color={'primary.400'}
              colorScheme={'primary'}
              onClick={() => {
                handleDeleteOperation(id)
                dispatch(clearGlobalMessage())
              }}
            >
              Ok
            </Button>
            <Button
              variant={'outline'}
              color={'red.400'}
              colorScheme={'red'}
              mt={6}
              onClick={() => {
                dispatch(clearGlobalMessage())
              }}
            >
              Cancelar
            </Button>
          </>
        )
      })
    )
  }
  const handleDeleteOperation = async (id: number) => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )
      await apiClient.operations.delete(id)
      loading.on()
      apiClient.operations
        .processFilter(filterAPI as any)
        .then((res) => res.process)
        .then((data) => {
          setOperations(() => data)
        })
        .finally(loading.off)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }
  /* useEffect(() => {
    if (reload) {
      loading.on()
      if (mode === 'one') {
        apiClient.operations
          .processFilter(filterAPI as any)
          .then((res) => res.process)
          .then((data) => {
            setOperations(() => data)
          })
          .finally(loading.off)
        setReload(false)
      } else if (mode === 'two') {
        apiClient.operations
          .processLoadSpreadSheets(filterAPI as any, isDascam)
          .then((res) => res.data)
          .then((res) => res.excel)
          .then((data) => {
            setSpreadsheets(() => data)
          })
          .finally(loading.off)
      }
    }
  }, [hashData])*/
  const loadProcess = useCallback(async () => {
    if (filterAPI) {
      loading.on()

      if (!isDascam) {
        let itemsToFind
        itemsToFind =
          groupList.length !== 0
            ? groupList
            : await apiClient.customers.getCustomersByUser(
                user.company_id ?? user.id!
              )

        if (user.permission_group_company) {
          itemsToFind = itemsToFind.filter((e) =>
            user.permission_group_company?.includes(e.id_syscambio)
          )
        }
        setGroupList(itemsToFind)

        if (!filterAPI.id_syscambio) {
          filterAPI.id_syscambio = itemsToFind.map((e) => e.id_syscambio)
        }
      }

      apiClient.operations
        .processFilter(filterAPI as any)
        .then((res) => res.process)
        .then((data) => {
          setOperations(() => data)
        })
        .finally(loading.off)
    }
  }, [filterAPI, isDascam])
  const loadExcel = useCallback(async () => {
    if (filterAPI) {
      loading.on()

      if (!isDascam) {
        let itemsToFind
        itemsToFind =
          groupList.length !== 0
            ? groupList
            : await apiClient.customers.getCustomersByUser(
                user.company_id ?? user.id!
              )

        if (user.permission_group_company) {
          itemsToFind = itemsToFind.filter((e) =>
            user.permission_group_company?.includes(e.id_syscambio)
          )
        }
        setGroupList(itemsToFind)

        if (!filterAPI.id_syscambio) {
          filterAPI.id_syscambio = itemsToFind.map((e) => e.id_syscambio)
        }
      }

      apiClient.operations
        .processLoadSpreadSheets(filterAPI as any, isDascam)
        .then((res) => res.data)
        .then((res) => res.excel)
        .then((data) => {
          setSpreadsheets(() => data)
        })
        .finally(loading.off)
    }
  }, [filterAPI, isDascam])

  return (
    <CardPage
      title={mode === 'one' ? 'Processos Manuais' : 'Planilhas de Processos'}
      _headerRight={
        <VStack spacing={4} align='stretch'>
          <Box h='35px' mt={1}>
            {
              <Button
                textColor={mode === 'one' ? 'white' : 'primary.400'}
                size='sm'
                variant={mode === 'one' ? 'solid' : 'outline'}
                colorScheme={'primary'}
                borderRightRadius={'0px'}
                borderColor={mode === 'one' ? 'primary.400' : 'primary'}
                opacity={mode === 'one' ? 1 : 0.5}
                onClick={() => {
                  setMode('one')
                  loadProcess()
                  hashData.mode = 'one'
                  AppContext.operations = hashData
                  redirect(hashData)
                }}
              >
                Preenchimento Manual
              </Button>
            }
            {
              <Button
                size='sm'
                variant={mode === 'two' ? 'solid' : 'outline'}
                colorScheme={'primary'}
                borderLeftRadius={'0px'}
                borderColor={mode === 'two' ? 'primary.400' : 'primary'}
                opacity={mode === 'two' ? 1 : 0.5}
                onClick={() => {
                  setMode('two')
                  loadExcel()
                  hashData.mode = 'two'
                  AppContext.operations = hashData
                  redirect(hashData)
                }}
              >
                Envio de Planilha
              </Button>
            }
          </Box>
        </VStack>
      }
    >
      <Col px={2} minH={'fill'} w={'100%'}>
        <Collapse in={isLoading}>
          <Progress isIndeterminate colorScheme={'secondary'} h={1} />
        </Collapse>
        {mode === 'one' ? (
          <SimpleFormGenerator
            rows={[
              {
                columns: 5,
                fields: [
                  {
                    type: 'select',
                    label: 'Tipo de Contrato',
                    name: 'operation_type',
                    options: [
                      {
                        label: () => 'Todos',
                        value: undefined
                      },
                      {
                        label: () => 'Remessa',
                        value: 'REMESSA'
                      },
                      {
                        label: () => 'Importação',
                        value: 'IMPORTACAO'
                      },
                      {
                        label: () => 'Ingresso',
                        value: 'INGRESSO'
                      },
                      {
                        label: () => 'Exportação',
                        value: 'EXPORTACAO'
                      }
                    ]
                  },

                  createCustomerSearchPicker(
                    !isDascam ? user.company_id ?? user.id! : 0,
                    user.cad_type === 'AUTORIZADO'
                      ? user.permission_group_company ?? []
                      : undefined,
                    {
                      label: 'Empresa',
                      name: 'customer',
                      labelKey: 'fantasy_name'
                    }
                  ),
                  {
                    type: 'text',
                    label: 'CNPJ/CPF',
                    name: 'customer.document',
                    readOnly: true,
                    mask (data) {
                      return String(data).length === 11
                        ? formatCPF(data)
                        : formatCNPJ(data)
                    }
                  },
                  {
                    type: 'date',
                    label: 'Período',
                    name: 'start_date'
                  },
                  {
                    type: 'date',
                    label: 'até',
                    name: 'end_date'
                  }
                ]
              },
              {
                columns: 4,
                fields: [
                  createCurrencySearchPicker(
                    {
                      label: 'Moeda',
                      name: 'currency_code',
                      labelKey: 'Nome'
                    },
                    currencies
                  ),
                  {
                    type: 'select',
                    label: 'Status',
                    name: 'status',
                    options: [
                      {
                        label: () => 'Todos',
                        value: undefined
                      },
                      {
                        label: () => 'Em Analise',
                        value: 'SENT'
                      },
                      {
                        label: () => 'Rascunho',
                        value: 'PENDING'
                      },
                      {
                        label: () => 'Em Correção',
                        value: 'CORRECTION'
                      },
                      {
                        label: () => 'Cancelado',
                        value: 'CANCELED'
                      },
                      {
                        label: () => 'Aprovado',
                        value: 'APPROVAL'
                      }
                    ]
                  },
                  createPayerReceiverSearchPicker(
                    hashData?.customer?.id_syscambio,
                    {
                      readOnly: !hashData?.customer?.id_syscambio,
                      label: 'Pagador/Recebedor',
                      name: 'payer_receiver',
                      labelKey: 'NomeBeneficiario'
                    }
                  )
                ]
              }
            ]}
            value={hashData}
            onChange={(v) => {
              const { fantasy_name, document, id_syscambio } = v.customer || {}
              const { CodPagReg, NomeBeneficiario } = v.payer_receiver || {}

              const nextParams: any = { ...v }

              if (v.customer) {
                nextParams.customer = {
                  fantasy_name,
                  document,
                  id_syscambio
                }
              }

              if (v.payer_receiver) {
                nextParams.payer_receiver = { CodPagReg, NomeBeneficiario }
              }

              if (v.currency_code) {
                nextParams.currency_code = { ...v.currency_code }
              }

              redirect(nextParams)
            }}
          />
        ) : (
          <SimpleFormGenerator
            rows={[
              {
                columns: 2,
                fields: [
                  // {
                  //   type: "text",
                  //   label: "Cod. Cliente",
                  //   name: "customer.id_syscambio",
                  //   readOnly: true,
                  // },
                  createCustomerSearchPicker(
                    !isDascam ? user.company_id ?? user.id! : 0,
                    user.cad_type === 'AUTORIZADO'
                      ? user.permission_group_company ?? []
                      : undefined,
                    {
                      label: 'Empresa',
                      name: 'customer',
                      labelKey: 'fantasy_name'
                    }
                  ),
                  {
                    type: 'text',
                    label: 'CNPJ/CPF',
                    name: 'customer.document',
                    readOnly: true,
                    mask (data) {
                      return String(data).length === 11
                        ? formatCPF(data)
                        : formatCNPJ(data)
                    }
                  }
                ]
              },
              {
                columns: 3,
                fields: [
                  {
                    type: 'date',
                    label: 'Período',
                    name: 'start_date'
                  },
                  {
                    type: 'date',
                    label: 'até',
                    name: 'end_date'
                  },
                  {
                    type: 'select',
                    label: 'Status',
                    name: 'status',
                    options: [
                      {
                        label: () => 'Todos',
                        value: undefined
                      },
                      {
                        label: () => 'Em Analise',
                        value: 'SENT'
                      },
                      {
                        label: () => 'Rascunho',
                        value: 'PENDING'
                      },
                      {
                        label: () => 'Em Correção',
                        value: 'CORRECTION'
                      },
                      {
                        label: () => 'Cancelado',
                        value: 'CANCELED'
                      },
                      {
                        label: () => 'Aprovado',
                        value: 'APPROVAL'
                      }
                    ]
                  }
                ]
              }
            ]}
            value={hashData}
            onChange={(v) => {
              const { fantasy_name, document, id_syscambio } = v.customer || {}
              const { CodPagReg, NomeBeneficiario } = v.payer_receiver || {}

              const nextParams: any = { ...v }

              if (v.customer) {
                nextParams.customer = {
                  fantasy_name,
                  document,
                  id_syscambio
                }
              }

              if (v.payer_receiver) {
                nextParams.payer_receiver = { CodPagReg, NomeBeneficiario }
              }

              if (v.currency_code) {
                nextParams.currency_code = { ...v.currency_code }
              }

              redirect(nextParams)
            }}
          />
        )}
        {mode === 'one' && (
          <VStack spacing={4} align='end'>
            <Box h='40px' mt={0}>
              {!isDascam && (
                <Link to={`/operations/create`}>
                  <Button
                    disabled={permCreation}
                    variant={'outline'}
                    color={'green.400'}
                    mr='10px'
                    colorScheme={'green'}
                  >
                    + Novo Processo
                  </Button>
                </Link>
              )}
              {
                <Button
                  variant={'outline'}
                  color={'red.400'}
                  colorScheme={'red'}
                  mr='10px'
                  onClick={() => {
                    clearData()
                    AppContext.operations = hashData
                  }}
                >
                  Limpar Filtro
                </Button>
              }
              {
                <Button
                  variant={'outline'}
                  color={'primary.400'}
                  colorScheme={'primary'}
                  onClick={() => {
                    loadProcess()
                    AppContext.operations = hashData
                  }}
                >
                  Buscar Processos
                </Button>
              }
            </Box>
          </VStack>
        )}
        {mode === 'two' && (
          <VStack spacing={4} align='end'>
            <Box h='40px' mt={2}>
              {!isDascam && (
                <Link to={`/spreadsheets/create`}>
                  <Button
                    disabled={permCreation}
                    variant={'outline'}
                    color={'green.400'}
                    mr='10px'
                    colorScheme={'green'}
                  >
                    + Enviar Planilha de Processos
                  </Button>
                </Link>
              )}
              {
                <Button
                  variant={'outline'}
                  color={'red.400'}
                  colorScheme={'red'}
                  mr='10px'
                  onClick={() => {
                    clearData()
                    setMode('two')
                    AppContext.operations = hashData
                  }}
                >
                  Limpar Filtro
                </Button>
              }
              {
                <Button
                  variant={'outline'}
                  color={'primary.400'}
                  colorScheme={'primary'}
                  onClick={() => {
                    loadExcel()
                    AppContext.operations = hashData
                  }}
                >
                  Buscar Planilhas
                </Button>
              }
            </Box>
          </VStack>
        )}
      </Col>
      <Col id='cont' minH={'60%'} px={2}>
        <Col
          mt={1}
          rounded={'lg'}
          overflow={'auto'}
          border={'1px solid transparent'}
          borderColor={'primary.600'}
        >
          {mode === 'one' ? (
            <>
              <DataTable
                noDataComponent=''
                columns={columns}
                data={operations.filter((e) =>{
                    return e.id?.toString().includes(filterTextExternal)
                 })}
                dense={true}
                fixedHeader={true}
                fixedHeaderScrollHeight={`${
                  (document.getElementById('cont')?.clientHeight ?? 0) - 25
                }px`}
                striped={true}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
              />
            </>
          ) : (
            /* table show clients */
            <DataTable
              noDataComponent=''
              columns={columnsOperationExcel}
              data={spreadsheets.filter((e) =>{
                return e.id?.toString().includes(filterTextExternal)
             })}
              dense={true}
              fixedHeader={true}
              fixedHeaderScrollHeight={`${
                (document.getElementById('cont')?.clientHeight ?? 0) - 25
              }px`}
              striped={true}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
            />
          )}
        </Col>
      </Col>

      <Historic
        showModal={showModal}
        handleModal={() => setShowModal(!showModal)}
        operation={operation}
      />
    </CardPage>
  )
}

export default ListOperationsPage
