import { PayerReceiverModel } from "interfaces/payiesReceivies";
import { ColumnFieldByName } from "v2/components/shared/SimpleFormGenerator";
import { apiClient } from "v2/services/clients/apiClient";

export function createPayerReceiverSearchPicker(
    idSyscambio?: string,
    options?: Partial<ColumnFieldByName["search-picker"]>,
    initialItems?: PayerReceiverModel[]
): ColumnFieldByName["search-picker"] {
    return {
        label: "Pagador/Recebedor",
        name: "payer_receiver",
        type: "search-picker",
        labelKey: "NomeBeneficiario",
        onSearch: async () => {
            const items = initialItems
                ? initialItems
                : idSyscambio
                    ? await apiClient.payer.all(idSyscambio)
                    : [];

            return Promise.resolve({
                items,
                _modal: { size: "6xl", scrollBehavior: "inside" },
                title: "Selecione um Pagador Recebedor",
                uuidField: "CodPagReg",
                columns: [
                    {
                        key: "NomeBeneficiario",
                        title: "Pagador/Recebedor no Estrangeiro",
                        defaultLabel: "Não definido",
                    },
                    {
                        key: "CodPaisBeneficiario",
                        title: "País",
                        defaultLabel: "Não definido",
                    },
                    {
                        key: "ContaBancoBeneficiario",
                        title: "Conta",
                        defaultLabel: "Não definido",
                    },
                ],
                search: {
                    initialValue: {
                        id: "",
                        name: "",
                        country: "",
                        account: "",
                    },
                    rows: [
                        {
                            columns: 3,
                            fields: [
                                {
                                    key: "name",
                                    label: "Pagador/Recebedor no Exterior",
                                    type: "text",
                                    placeholder: "Pesquisar por nome",
                                },
                                {
                                    key: "country",
                                    label: "País",
                                    type: "text",
                                    placeholder: "Pesquisar por país",
                                },
                                {
                                    key: "account",
                                    label: "Conta",
                                    type: "text",
                                    placeholder: "Pesquisar por conta",
                                },
                            ],
                        },
                    ],
                    filters: {
                        id: ["CodPagReg"],
                        name: ["NomeBeneficiario"],
                        country: ["CodPaisBeneficiario"],
                        account: ["ContaBancoBeneficiario"],
                    },
                },
            });
        },
        ...options,
    };
}
